import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Form from '@nubank/nuds-web/components/Form/Form';
import Button from '@nubank/nuds-web/components/Button/Button';

import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

import { SiteContext } from '../SiteContext/SiteContext';
import { FormContainer } from '../../screens/Home/patterns/Hero/styles/FormContainer';

const MPSignUpForm = ({
  idTextField, placeholder, label, btnLabel, validateDisabled,
}) => {
  const { formatMessage } = useIntl();
  const { setProspectEmail, toggleRegistrationForm } = useContext(SiteContext);

  const handleSubmit = async ({
    values,
    setSubmitting,
  }) => {
    setProspectEmail(values.email);
    setSubmitting(false);
    toggleRegistrationForm();
    sendEvent('PROSPECT_REGISTRATION_WAITLIST_STARTED');
  };
  return (
    <Form>
      <Form.Step
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, isDisabled }) => (
          <FormContainer className="form-container">
            <TextField
              id={idTextField}
              type="email"
              name="email"
              placeholder={formatMessage({ id: placeholder })}
              label={formatMessage({ id: label })}
              data-gtm="home-hero-email-field"
              autoComplete="off"
              syncValidations={{
                required: formatMessage({ id: 'HOME.HERO.FORM.EMAIL_REQUIRED' }),
                email: formatMessage({ id: 'HOME.HERO.FORM.EMAIL_VALIDATION' }),
              }}
              className="mp-signup-form-text"
            />
            <Button
              extended
              id="email-on-hero-submit-btn"
              data-testid="email-on-hero-submit-btn"
              data-gtm="home-hero-email-cta-btn"
              disabled={(validateDisabled && isDisabled) || isSubmitting}
              type="submit"
              variant="contained"
              styleVariant="black"
              // color="black"
              intlKey={btnLabel}
              iconProps={{ name: 'arrow-right' }}
              className="mp-signup-form-button"
            />
          </FormContainer>
        )}
      </Form.Step>
    </Form>
  );
};

MPSignUpForm.defaultProps = {
  btnLabel: 'HOME.HERO.FORM.BUTTON_LABEL',
  idTextField: 'email-on-hero',
  label: 'HOME.HERO.FORM.LABEL',
  placeholder: 'HOME.HERO.FORM.PLACEHOLDER',
  validateDisabled: false,
};

MPSignUpForm.propTypes = {
  btnLabel: PropTypes.string,
  idTextField: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validateDisabled: PropTypes.bool,
};

export default MPSignUpForm;
